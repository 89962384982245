$(function () {
    $('.home-slider .slider').bxSlider({
        mode: 'fade',
        controls: true,
        nextText: '<i class="fa fa-angle-right"></i>',
        prevText: '<i class="fa fa-angle-left"></i>',
        onSliderLoad: function () {
            $('.home-slider').css('visibility', 'visible');
        }
    });
    $.each($('.btn-oliver'), function (i, k) {
        var btnText = $(k).text();
        $(k).text('');
        var arrow = '<span>' + btnText + ' <i class="fa fa-angle-right"></i></span>';
        $(k).append($(arrow));
    });
    var mailLink = $('.sub-menu li a').filter(function (index) {
        return $(this).text() === "office@fantasteak.de";
    });
    $(mailLink).attr('href', 'mailto:office@fantasteak.de');
});
var slideroto = SLIDER.auto;
if (slideroto == true) var slidersure = SLIDER.time;
else var slidersure = false;
var infosoto = SLIDER.infoauto;
if (infosoto == true) var infossure = SLIDER.infotime;
else var infossure = false;

function getWidth() {
    if (self.innerHeight) {
        return self.innerWidth;
    }
    if (document.documentElement && document.documentElement.clientWidth) {
        return document.documentElement.clientWidth;
    }
    if (document.body) {
        return document.body.clientWidth;
    }
}
var screen_w = getWidth();

function googleTranslateElementInit() {
    new google.translate.TranslateElement({
        pageLanguage: 'de',
        includedLanguages: 'en',
        layout: google.translate.TranslateElement.InlineLayout.SIMPLE,
        multilanguagePage: true,
        autoDisplay: false
    }, 'google_translate_element');
}

function checkTranslation() {
    var isTranslated = $('html').hasClass('translated-ltr');
    if (isTranslated) {
        $('.main-lang').text('ENGLISH');
    } else {
        $('.main-lang').text('DEUTSCH');
    }
}
setInterval(function () {
    checkTranslation();
}, 3000);
$(function () {
    $('.change-en').on('click', function (e) {
        e.preventDefault();
        var lang = $(this).data('lang');
        var $frame = $('.goog-te-menu-frame:first');
        if (!$frame.size()) {
            alert("Error: Could not find Google translate frame.");
            return false;
        }
        $frame.contents().find('.goog-te-menu2-item span.text').trigger('click');
        $('.main-lang').text('ENGLISH');
    });
});
$(function () {
    $('.search-form, .m-search').on('submit', function (e) {
        e.preventDefault();
        e.stopPropagation();
        var me = $(this);
        window.location = me.attr('action') + $.trim($('.search-field', me).val());
    });
});

function urunsay() {
    $.post(SITEURL + "index.php?cmd=islem", {
        islem: "urun_say"
    }).done(function (data) {
        $('.cart-count').html(data.trim());
    });
}
urunsay();

function toplam_hesapla() {
    $.post(SITEURL + "index.php?cmd=islem", {
        islem: "toplam_hesapla"
    }).done(function (data) {
        $('.cal-shipping').html(data);
    });
    $.post(SITEURL + "index.php?cmd=islem", {
        islem: "toplam_hesapla2"
    }).done(function (data) {
        $('.cal-shipping2').html(data);
    });
}

function sil(id, olcu) {
    if (id == 0) {
        return false;
    }
    $.post(SITEURL + "index.php?cmd=islem", {
        id: id,
        olcu: olcu,
        islem: "sepet_sil"
    }).done(function (data) {
        $('.sepet_row_' + id + '_' + olcu).hide();
        toplam_hesapla();
        urunsay();
        window.location.reload();
    });
}

function arttir(id, olcu) {
    if (id == 0) {
        $('#bayi').html('');
        return false;
    }
    $.post(SITEURL + "index.php?cmd=islem", {
        id: id,
        olcu: olcu,
        islem: "arttir"
    }).done(function (data) {
        $('.price_' + id + '_' + olcu).html(data);
        toplam_hesapla();
        //location.reload();
    });
    return true;
};

function azalt(id, olcu) {
    $.post(SITEURL + "index.php?cmd=islem", {
        id: id,
        olcu: olcu,
        islem: "azalt"
    }).done(function (data) {
        $('.price_' + id + '_' + olcu).html(data);
        toplam_hesapla();
        //location.reload();
    });
};

function sepetEkle(id) {
    var mobil = 0;
    if ($(document).width() < 900) {
        mobil = 1;
    } else {
        mobil = 0;
    }
    //return false;
    $('.errr').removeClass('errr');
    var olcu = $('#olcu').val();
    var materyal = $('#materyal').val();
    var yuzey = $('#yuzey').val();
    var adet = $('#adet').val();
    var ozel = $('#ozel').val();
    var ozel_olcu = "";
    var size1, size2, size3;
    if (ozel == 1) {
        size1 = $('#size1').val();
        size2 = $('#size2').val();
        size3 = $('#size3').val();
        var errr = 0;
        if (size1 == "") {
            $('#size1').addClass('errr');
            errr = 1;
        }
        if (size2 == "") {
            $('#size2').addClass('errr');
            errr = 1;
        }
        if (size3 == "") {
            $('#size3').addClass('errr');
            errr = 1;
        }
        if (errr == 1) {
            alert('Bitte geben Sie Dimensionen.');
            return false;
        }
        ozel_olcu = size1 + " x " + size2 + " x " + size3 + " cm";
    }
    if (id == 0) {
        return false;
    }
    $.post(SITEURL + "index.php?cmd=islem", {
        id: id,
        islem: "on_sepet",
        olcu: olcu,
        materyal: materyal,
        yuzey: yuzey,
        adet: adet,
        ozel: ozel,
        ozel_olcu: ozel_olcu,
        size1: size1,
        size2: size2,
        size3: size3
    }).done(function (data) {
        //$('.amount').html(data);
        if (mobil == 1) {
            location.href = SITELINK + 'bestellkorb/hinzufugen/m/';
        } else {
            //$('#sepet_click').trigger('click');
            location.href = SITELINK + 'bestellkorb/hinzufugen/';
        }
    });
}

function fiyatGetir(id) {
    if (id == 0) {
        return false;
    }
    $.post(SITEURL + "index.php?cmd=islem", {
        id: id,
        islem: "size"
    }).done(function (data) {
        $('.amount').html(data);
    });
    $.post(SITEURL + "index.php?cmd=islem", {
        id: id,
        islem: "size_art"
    }).done(function (data) {
        $('.art-no').html(data);
    });
}

function bayiGoster() {
    var bayi = $('#bayi').val();
    var altKat = $('#altKat').val();
    if (bayi != 0 && altKat != 0) {
        $('.pin').hide();
        $('.pin_' + bayi).show();
    } else {
        if (bayi == 0 && altKat == 0) {
            $('.pin').show();
        } else if (bayi == 0) {
            $('.pin').hide();
            $('.tur_' + altKat).show();
        } else {
            $('.pin').show();
        }
    }
}

$(function () {
   
    var slider = $('.product-image-slider').bxSlider({
        pager: false,
        controls: true,
        infiniteLoop: false,
        nextText: '<i class="fa fa-angle-right"></i>',
        prevText: '<i class="fa fa-angle-left"></i>',
        onSliderLoad: function () {
            $('.product-image-slider li').css('visibility', 'visible');

            $('.single-product .p-thumb > ul').bxSlider({
                mode: 'horizontal',
                slideMargin: 5,
                slideWidth: 122,
                moveSlides: 2,
                minSlides: 4,
                maxSlides: 5,
                pager: false,
                infiniteLoop: true,
                controls: true,
                nextText: '<i class="fa fa-angle-right"></i>',
                prevText: '<i class="fa fa-angle-left"></i>',
                onSliderLoad: function () {
                    $('.prod-thumb li a').on('click', function (e) {
                        e.preventDefault();
                        var thumbID = $(this).attr('data-thumb');
                        slider.goToSlide(thumbID);
                    });
                }
            });
        }
    });
});
$(function () {
    $('.tooltipster').tooltipster({
        theme: 'tooltipster-shadow',
        trigger: 'click'
    });
    if ($('.go-to-top').length) {
        var scrollTrigger = 200, // px
            backToTop = function () {
                var scrollTop = $(window).scrollTop();
                if (scrollTop > scrollTrigger) {
                    $('.go-to-top').addClass('show');
                } else {
                    $('.go-to-top').removeClass('show');
                }
            };
        backToTop();
        $(window).on('scroll', function () {
            backToTop();
        });
        $('.go-to-top').on('click', function (e) {
            e.preventDefault();
            $('html,body').animate({
                scrollTop: 0
            }, 700);
        });
    }
    $('.carousel[data-type="multi"] .item').each(function () {
        var next = $(this).next();
        if (!next.length) {
            next = $(this).siblings(':first');
        }
        next.children(':first-child').clone().appendTo($(this));
        for (var i = 0; i < 2; i++) {
            next = next.next();
            if (!next.length) {
                next = $(this).siblings(':first');
            }
            next.children(':first-child').clone().appendTo($(this));
        }
    });
    $('.carousel').bcSwipe({
        threshold: 100
    });
});
$(function () {
    "use strict";
    $('.category-slider').owlCarousel({
        items: 3,
        itemsCustom: false,
        itemsDesktop: [1199, 2],
        itemsDesktopSmall: [980, 2],
        itemsTablet: [768, 2],
        itemsTabletSmall: false,
        itemsMobile: [479, 1],
        singleItem: false,
        itemsScaleUp: false,
        navigation: true,
        pagination: false,
        autoPlay: false,
        navigationText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>']
    });
    $('.info-slider2').owlCarousel({
        singleItem: false,
        navigation: true,
        loop: true,
        pagination: false,
        autoPlay: infossure,
        navigationText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
        responsive: {
            0: {
                items: 1
            },
            540: {
                items: 1
            },
            1024: {
                items: 5
            },
            1600: {
                items: 5
            }
        }
    });
    if ($(document).width() < 541) {
        var info_slider_wid = ($(document).width() - 44) / 2;
    } else {
        var info_slider_wid = 221;
    }
    var width = $(window).width();
    $('.info-slider').bxSlider({
        mode: 'horizontal',
        slideMargin: 5,
        slideWidth: info_slider_wid,
        moveSlides: 2,
        minSlides: 1,
        maxSlides: (width < 430) ? 2 : 5,
        pager: false,
        infiniteLoop: true,
        controls: true,
        nextText: '<i class="fa fa-angle-right"></i>',
        prevText: '<i class="fa fa-angle-left"></i>'
    });
    $('.about-slider').owlCarousel({
        singleItem: true,
        navigation: false,
        pagination: true,
        autoPlay: false
    });
    $('.about-slider-text').owlCarousel({
        singleItem: true,
        navigation: false,
        pagination: true,
        autoPlay: false
    });
    (function ($) {
        $(document).ready(function () {
            $('#cssmenu li.active').addClass('open').children('ul').show();
            $('#cssmenu li.has-sub>a').on('click', function () {
                $(this).removeAttr('href');
                var element = $(this).parent('li');
                if (element.hasClass('open')) {
                    element.removeClass('open');
                    element.find('li').removeClass('open');
                    element.find('ul').slideUp(200);
                } else {
                    element.addClass('open');
                    element.children('ul').slideDown(200);
                    element.siblings('li').children('ul').slideUp(200);
                    element.siblings('li').removeClass('open');
                    element.siblings('li').find('li').removeClass('open');
                    element.siblings('li').find('ul').slideUp(200);
                }
            });
            var nachmass = 0;
            $('.special').change(function () {
                if ($(this).val() == "nachmass") {
                    nachmass = 1;
                    $('.sector_2').css({
                        opacity: 1
                    });
                    $('.normal_attr').css({
                        opacity: .3
                    });
                    $('.normal_btn').css({
                        opacity: .3
                    });
                    $('.nachmass_btn').css({
                        opacity: 1
                    });
                    $('#ozel').val(1);
                } else {
                    nachmass = 0;
                    $('.sector_2').css({
                        opacity: .3
                    });
                    $('.normal_attr').css({
                        opacity: 1
                    });
                    $('.normal_btn').css({
                        opacity: 1
                    });
                    $('.nachmass_btn').css({
                        opacity: .3
                    });
                    $('#ozel').val(0);
                }
            });
            $('#siparis_ver_form').submit(function () {
                $('.errr').removeClass('errr')
                var errr = 0;
                $('.req').each(function () {
                    if ($(this).val() == "") {
                        $(this).addClass('errr');
                        errr = 1;
                    };
                });
                if (errr) {
                    alert('Füllen Sie die erforderlichen Felder aus.');
                    return false;
                }
            });
            $('#mobelnachmassForm').submit(function () {
                $('.errr').removeClass('errr')
                var errr = 0;
                $('.req').each(function () {
                    if ($(this).val() == "") {
                        $(this).addClass('errr');
                        errr = 1;
                    };
                });
                if (errr) {
                    alert('Füllen Sie die erforderlichen Felder aus.');
                    return false;
                }
            });
            $('.mobile-search').click(function () {
                $('.m-search').toggleClass("on");
            });
        });
    })(jQuery);
});
(function ($) {
    "use strict";
    $(document).ready(function () {
        /*  [ Dropdown ]
            - - - - - - - - - - - - - - - - - - - - */
        $("#cssmenu > ul > li:eq(0)").before($("#cssmenu > ul > li.active"));
        $(".mobile-cats > li:eq(0)").before($(".mobile-cats > li.active"));
        $('.dropdown').each(function () {
            var _this = $(this);
            $(this).find('a').on('click', function (e) {
                e.preventDefault();
                $(_this).toggleClass('open');
                var value = $(this).attr('href');
                //$( _this ).find( '> ul > li > a' ).text( value );
                window.location.assign(value);
            });
        });
        $('html').on('click', function (e) {
            if ($(e.target).closest('.dropdown.open').length == 0) {
                $('.dropdown').removeClass('open');
            }
        });
        /*  [ Main Menu ]
            - - - - - - - - - - - - - - - - - - - - */
        $('.sub-menu').each(function () {
            $(this).parent().addClass('has-child').find('> a').append('<span class="arrow"></span>');
        });
        $('.main-menu .arrow').on('click', function (e) {
            e.preventDefault();
            $(this).parents('li').find('> .sub-menu').slideToggle('fast');
        });
        $('.mobile-menu').on('click', function () {
            $(this).parent().toggleClass('open');
        });
        $('.site-header-mobile .has-child > a').on('click', function (e) {
            var container = $(this).parent();
            var me = $('.sub-menu', container);
            if (!me.is('.mega-menu')) {
                e.preventDefault();
                $('.sub-menu').not(me).slideUp();
                me.slideToggle();
            }
        });
        $('.menu-toggle').on('click', function () {
            $('.main-menu').toggleClass('on');
            $(this).toggleClass('on');
        });
        /*  [ Newsletter ]
            - - - - - - - - - - - - - - - - - - - - */
        $('.site-newsletter form input[type=email], .newsletter-popup form input[type=email]').each(function () {
            var label = $(this).parent().find($('label'));
            $(this).keyup(function () {
                if ($(this).val().length > 0) {
                    $(label).hide();
                }
            });
            $(this).blur(function () {
                if ($(this).val().length > 0) {
                    $(label).hide();
                } else {
                    $(label).show();
                }
            });
            $(this).on('change', function () {
                $(label).hide();
            });
        });
        /*  [ Search box ]
            - - - - - - - - - - - - - - - - - - - - */
        $('.searchbox .icon').on('click', function () {
            $(this).parent().addClass('open');
        });
        /*  [ Shop cart ]
            - - - - - - - - - - - - - - - - - - - - */
        $('.shop-cart').on('click', function () {
            $('body').toggleClass('shop-cart-open');
            $('.shop-item').toggleClass('active');
        });
        /*  [ prettyPhoto ]
            - - - - - - - - - - - - - - - - - - - - */
        $("a[data-gal^='prettyPhoto'], a[rel^='prettyPhoto']").on('click', function (e) {
            e.preventDefault();
        });
        $("a[data-gal^='prettyPhoto']").prettyPhoto({
            hook: 'data-gal',
            animation_speed: 'normal',
            theme: 'light_square',
            slideshow: 3000,
            social_tools: false
        });
        $("a[rel^='prettyPhoto']").prettyPhoto({
            hook: 'data-gal',
            animation_speed: 'normal',
            theme: 'light_square',
            slideshow: 3000,
            social_tools: false
        });
        /*  [ Add minus and plus number quantity ]
            - - - - - - - - - - - - - - - - - - - - */
        if ($('.quantity').length > 0) {
            var form_cart = $('form .quantity');
            form_cart.prepend('<span class="minus"><i class="fa fa-minus"></i></span>');
            form_cart.append('<span class="plus"><i class="fa fa-plus"></i></span>');
            var minus = form_cart.find($('.minus'));
            var plus = form_cart.find($('.plus'));
            minus.on('click', function () {
                var qty = $(this).parent().find('.qty');
                if (qty.val() <= 1) {
                    qty.val(1);
                } else {
                    qty.val((parseInt(qty.val(), 10) - 1));
                }
                azalt($(this).parent().data("id"), $(this).parent().data("value"));
            });
            plus.on('click', function () {
                var qty = $(this).parent().find('.qty');
                qty.val((parseInt(qty.val(), 10) + 1));
                arttir($(this).parent().data("id"), $(this).parent().data("value"));
            });
        }
        /*  [ Bxslider ]
            - - - - - - - - - - - - - - - - - - - - */
        $('.main-bxslider .slider').bxSlider({
            mode: "fade",
            controls: false
        });
        
        /*  [ Owl Carousel slider ]
            - - - - - - - - - - - - - - - - - - - - */
        $('.product-slider .slider').owlCarousel({
            items: 4,
            itemsDesktop: [1199, 4],
            itemsDesktopSmall: [989, 3],
            itemsTablet: [767, 2],
            itemsMobile: [540, 1],
            pagination: false,
            navigation: true,
            navigationText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>']
        });
        /*  [ Quickview ]
            - - - - - - - - - - - - - - - - - - - - */
        $('.quick-view').on('click', function (e) {
            e.preventDefault();
            var _this = $(this).parents('.product');
            $(_this).find('.popup').addClass('popup-open');
            if ($(this).parents('.product-slider').length > 0) {
                $(this).parents('.product-slider').addClass('open-quickview');
            }
            setTimeout(function () {
                $(_this).find('.p-thumb > ul').bxSlider({
                    mode: 'vertical',
                    slideMargin: 10,
                    minSlides: 4,
                    maxSlides: 4,
                    pager: false,
                    controls: true,
                    nextText: '<i class="fa fa-caret-down"></i>',
                    prevText: '<i class="fa fa-caret-up"></i>'
                });
            }, 100);
        });
        /*  [ Popup ]
            - - - - - - - - - - - - - - - - - - - - */
        $('.popup-close').on('click', function (e) {
            e.preventDefault();
            $(this).parents('.popup').removeClass('popup-open');
            var _this = $(this);
            if ($(_this).parents('.product-slider').length > 0) {
                setTimeout(function () {
                    $(_this).parents('.product-slider').removeClass('open-quickview');
                }, 100);
            }
        });
        /*  [ Tabs ]
            - - - - - - - - - - - - - - - - - - - - */
        $('.tabs-container').each(function () {
            $(this).find('.tabs li:first-child a').addClass('active');
            $(this).find('.tab-content:first').show();
            $(this).find('.tabs li a').on('click', function (e) {
                var durum = $(this).attr('class');
                e.preventDefault();
                var selector = $(this).attr('href');
                if (durum == "") {
                    $(this).addClass('active');
                    $(this).parent().parent().find('a').not(this).removeClass('active');
                    $(this).parents('.tabs-container').find('.tab-content').not(selector).slideUp(300);
                    $(this).parents('.tabs-container').find(selector).slideDown(300);
                } else {
                    $(this).removeClass('active');
                    $(this).parent().parent().find('a').not(this).removeClass('active');
                    $(this).parents('.tabs-container').find(selector).slideUp(300);
                }
            });
        });
        /*  [ Toogle ]
            - - - - - - - - - - - - - - - - - - - - */
        $('.toggle').each(function () {
            $(this).find('.toggle-controls li:first-child a').addClass('active');
            $(this).find('.toggle-content:first').show();
            $(this).find('.toggle-controls li a').on('click', function (e) {
                e.preventDefault();
                var selector = $(this).attr('href');
                $(this).parent().parent().find('a').not(this).removeClass('active');
                $(this).addClass('active');
                $(this).parents('.toggle').find('.toggle-content').not(selector).slideUp(300);
                $(this).parents('.toggle').find(selector).slideDown(300);
            });
        });
        /*  [ jQuery Countdown ]
            - - - - - - - - - - - - - - - - - - - - */
        var endDate1 = 'March 15, 2016';
        var endDate2 = 'March 2, 2016';
        $('.countdown.home1 ul').countdown({
            date: endDate1,
            render: function (data) {
                $(this.el).html('<li><span>' + this.leadingZeros(data.days, 2) + '</span> Days</li>' + '<li><span>' + this.leadingZeros(data.hours, 2) + '</span>Hours</li>' + '<li><span>' + this.leadingZeros(data.min, 2) + '</span>Mins</li>' + '<li><span>' + this.leadingZeros(data.sec, 2) + '</span>Secs</li>');
            }
        });
        $('.countdown.home2 ul').countdown({
            date: endDate2,
            render: function (data) {
                $(this.el).html('<li><span>' + this.leadingZeros(data.days, 2) + '</span> Days</li>' + '<li><span>' + this.leadingZeros(data.hours, 2) + '</span>Hours</li>' + '<li><span>' + this.leadingZeros(data.min, 2) + '</span>Mins</li>' + '<li><span>' + this.leadingZeros(data.sec, 2) + '</span>Secs</li>');
            }
        });
        /*  [ Burger Menu ]
            - - - - - - - - - - - - - - - - - - - - */
        $('.burger-control').on('click', function (e) {
            e.preventDefault();
            $('#popup-burger').addClass('popup-open');
        });
        $('.mobile-cats-close').click(function () {
            $('.mobile-cats').removeClass('on');
            $('.mobile-cats-down').show();
        });
        $('.mobile-cats-menu').click(function () {
            $('.mobile-cats').addClass('on');
            $('.mobile-cats-down').hide();
        });
        $('.mobile-cats .super').click(function () {
            $('.mobile-cats > li').show();
            $(this).hide();
        });
        /*  [ Mouse click scroll down ]
            - - - - - - - - - - - - - - - - - - - - */
        $('.mouse-scroll').on('click', function (e) {
            e.preventDefault();
            $("html, body").animate({
                scrollTop: $(window).height()
            }, 700, function () {
                $('.mid-header').addClass('show');
            });
        });
        /*  [ Sticky Menu ]
            - - - - - - - - - - - - - - - - - - - - */
        //$('.mid-header').sticky({ topSpacing: 0 });
        /*  [ Back to top ]
            - - - - - - - - - - - - - - - - - - - - */
        $(window).scroll(function () {
            if ($(this).scrollTop() > 50) {
                $('.back-to-top').addClass('show');
            } else {
                $('.back-to-top').removeClass('show');
            }
        });
        $('.back-to-top').on('click', function (e) {
            e.preventDefault();
            $("html, body").animate({
                scrollTop: 0
            }, 500);
        });
        /*  [ Animate Elements ]
            - - - - - - - - - - - - - - - - - - - - */
        var $animation_elements_in = $('.animation-element.fade-in');
        var $animation_elements_left = $('.animation-element.fade-left');
        var $animation_elements_right = $('.animation-element.fade-right');
        var $window = $(window);

        function check_if_in_view() {
            var window_height = $window.height();
            var window_top_position = $window.scrollTop();
            var window_bottom_position = (window_top_position + window_height);
            $.each($animation_elements_in, function () {
                var $element = $(this);
                var element_height = $element.outerHeight();
                var element_top_position = $element.offset().top;
                var element_bottom_position = (element_top_position + element_height);
                //check to see if this current container is within viewport
                if ((element_bottom_position >= window_top_position + 100) && (element_top_position <= window_bottom_position)) {
                    $element.addClass('animated fadeInUp');
                }
            });
            $.each($animation_elements_left, function () {
                var $element = $(this);
                var element_height = $element.outerHeight();
                var element_top_position = $element.offset().top;
                var element_bottom_position = (element_top_position + element_height);
                //check to see if this current container is within viewport
                if ((element_bottom_position >= window_top_position + 100) && (element_top_position <= window_bottom_position)) {
                    $element.addClass('animated fadeInLeft');
                }
            });
            $.each($animation_elements_right, function () {
                var $element = $(this);
                var element_height = $element.outerHeight();
                var element_top_position = $element.offset().top;
                var element_bottom_position = (element_top_position + element_height);
                //check to see if this current container is within viewport
                if ((element_bottom_position >= window_top_position + 100) && (element_top_position <= window_bottom_position)) {
                    $element.addClass('animated fadeInRight');
                }
            });
        }
        $window.on('scroll resize', check_if_in_view);
        $window.trigger('scroll');
    });
})(jQuery);